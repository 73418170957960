import { Spin } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

const SpinWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FullScreenSpin: FC = () => {
  return (
    <SpinWrapper>
      <Spin size='large' />
    </SpinWrapper>
  )
}

export default FullScreenSpin
